import * as React from 'react';
import AppFooter from '../Layout/AppFooter';
import withRoot from '../Theme/withRoot';
import Gallery from '../Gallery/Gallery';
import MainLayout from '../Layout/MainLayout';
import OurStory from '../Layout/OurStory';
import Registration from '../Registration/Registration';
import GiftInfo from '../Layout/GiftInfo';

function Index() {
  return (
    <>
      <MainLayout />
      <OurStory />
      <Gallery />
      <Registration />
      <GiftInfo />
      <AppFooter />
    </>
  );
}

export default withRoot(Index);
