import './App.css';
import Home from './components/Layout/Home';
import Snowfall from 'react-snowfall';

function App() {
  const currentMonth = new Date().getMonth();
  const activateSnowfall = currentMonth >= 11 || currentMonth <= 3;

  return (
    <div className='App'>
      {activateSnowfall && (
        <Snowfall
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh'
          }}
        />
      )}
      <Home />
    </div>
  );
}

export default App;
