import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../Theme/Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
});

function Help(props) {
  const { classes } = props;

  return (
    <Container className={classes.root} component="section">
      <img
        src="/static/custom-images/gift.svg"
        className={classes.buoy}
        alt="gift icon"
      />
      <Typography variant="h5" className={classes.link}>
        **Vaša prítomnosť na našej oslave je pre nás dôležitejšia ako darček.
        Vieme, že to nie je tradičné, nie je to tak, ako sa to robí.
        Žijeme spolu už dosť dlho a máme svoje taniere, hrnce a panvice.
        Ak si nás však chcete uctiť darčekom, váš finančný príspevok na svadobnú cestu by nám skutočne spríjemnil deň.
      </Typography>

    </Container>
  );
}

Help.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Help);
