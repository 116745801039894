import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../Theme/Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'normal',
    padding: theme.spacing(0, 6),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  h4: {
    marginBottom: theme.spacing(4),
    fontFamily: 'Times New Roman',
  },
});

function OurStory(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/static/themes/onepirate/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/custom-images/two-of-hearts.svg"
                alt="two of hearts"
              />
              <Typography variant="h2" className={classes.title}>
                Náš Príbeh
              </Typography>
              <Typography variant="h5" align='justify' >
                Matej a Marianna sa prvýkrát stretli počas túry pri krásnom jazere „Morskie oko“ v poľských Tatrách v novembri 2017. Po spoločných rozhovoroch o cestách, programovaní a živote sa navzájom pridali na Facebooku.
              </Typography>
              <Typography variant="h5" gutterBottom={true}>
                ...
              </Typography>
              <Typography variant="h5" align='justify'>
                Ale ich príbeh sa začal o pár mesiacov neskôr, keď sa on vrátil z cesty z Austrálie a ona sa vrátila z cesty z Malty. Dohodli sa, že spoločne oslávia Silvester. Po Novom roku sa bohužiaľ nič nestalo.
                Povedala mu: „Buďme priateľmi ...“.
              </Typography>
              <Typography variant="h5" gutterBottom={true}>
                ...
              </Typography>
              <Typography variant="h5" align='justify'>
                Ich priateľstvo sa ale rýchlo zmenilo na niečo viac. Takže nakoniec pochopili, že nie sú len kamaráti.
                A tu sa začal aj ich príbeh ...
              </Typography>
              <Typography variant="h5" gutterBottom={true}>
                ...
              </Typography>
              <Typography variant="h5" align='justify'>
              Svoj milostný príbeh mohli rozvíjať v Poľsku, ale rozhodli sa vyskúšať niečo iné. Obaja sa teda uchádzali o prácu vo Švédsku. A v septembri 2018 sa presťahovali do Göteborgu.
              </Typography>
              <Typography variant="h5" gutterBottom={true}>
                ...
              </Typography>
              <Typography variant="h5" align='justify'>
               V decembri 2019 pred Vianocami Matej položil otázku - Marianna povedala áno! V marci 2021 sa rozhodli to spravit oficialne a počas malého ceremoniálu sa z nich stali manželia.
              </Typography>
              <Typography variant="h5" gutterBottom={true}>
                ...
              </Typography>
              <Typography variant="h5" align='justify'>
                Sme nadšení z tejto novej kapitoly v našom spoločnom živote a chceme túto udalosť osláviť so svojimi blízkymi.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

OurStory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OurStory);
