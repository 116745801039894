import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '../Theme/Typography';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '../Theme/Snackbar';
import Button from '../Theme/Button';
import ReCAPTCHA from 'react-google-recaptcha';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: 'flex',
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifycontent: 'center',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.warning.main,
  },
  button: {
    width: '100%',
    fontFamily: theme.typography.fontFamily,
  },
  imagesWrapper: {
    position: 'relative',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
  },
});

function Registration(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);

  const initialValues = { fullName: '', email: '' };
  const [formValues, setFormValues] = React.useState(initialValues);
  const [formErrors, setFormErrors] = React.useState({});
  const [recaptchaVerified, setRecaptchaVerified] = React.useState(false);

  const recaptchaRef = React.createRef();

  const handleSubmit = event => {
    event.preventDefault();

    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (recaptchaVerified) {
        submitForm();
      } else {
        recaptchaRef.current.executeAsync().then(() => {
          submitForm();
        });
      }
    }
  };

  const submitForm = () => {
    alert('Registracia nie je možná.');
  };

  const validate = values => {
    let errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const fullNameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    if (!values.email) {
      errors.email = 'E-mail je povinný';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Nesprávny formát e-mailu';
    }
    if (!values.fullName) {
      errors.fullName = 'Meno s prezviskom su povinné';
    } else if (!fullNameRegex.test(values.fullName)) {
      errors.fullName = 'Meno a prezvisko obsahuje nepovolene znaky';
    }
    return errors;
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form onSubmit={handleSubmit} className={classes.cardContent} id="registration_form">
              <Typography variant="h2" component="h2" gutterBottom>
                Potvrdenie účasti
              </Typography>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel size="medium" htmlFor="email">
                  Tvoje meno
                </InputLabel>
                <FilledInput
                  size="medium"
                  color="primary"
                  name="fullName"
                  id="fullName"
                  value={formValues.fullName || ''}
                  onChange={handleChange}
                />
                {formErrors.fullName && (
                  <FormHelperText error id="component-helper-text">
                    {formErrors.fullName}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl variant="outlined" className={classes.textField}>
                <InputLabel htmlFor="component-outlined">Tvoj email</InputLabel>
                <FilledInput
                  color="primary"
                  name="email"
                  id="email"
                  value={formValues.email || ''}
                  onChange={handleChange}
                />
                {formErrors.email && (
                  <FormHelperText error id="component-helper-text">
                    {formErrors.email}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
              >
                Pridem
              </Button>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={() => setRecaptchaVerified(true)}
              />
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden mdDown>
            <img
              src="/static/custom-images/slavia.png"
              alt="envelope  pink flower rings "
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={'Do skorého videnia!'}
      />
    </Container>
  );
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Registration);
