import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../Theme/Button';
import Box from '@material-ui/core/Box';
import Typography from '../Theme/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import Link from '@material-ui/core/Link';
import { Fade } from '@material-ui/core';

const backgroundImage = '/static/custom-images/home-background.jpg';

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#f3c1c1', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
    fontFamily: theme.typography.fontFamily,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function MainLayout(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      <Fade in timeout={4000}>
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="wedding background rings on I love you book next to white roses"
      />
      </Fade>
      <Fade in timeout={4000}>
        <Typography color="inherit" align="center" variant="h1" marked="center">
          Matej & Marianna
        </Typography>
      </Fade>
      <Fade in timeout={7000}>
        <Typography
          color="inherit"
          align="center"
          variant="h5"
          className={classes.h5}
        >
          Vzali sme sa 20. marec 2021 v meste Göteborg, <br />
          teraz vás chceme pozvat na malú oslavu nášho dňa
        </Typography>
      </Fade>
      <Fade in timeout={7000}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.button}
          component="a"
          href="#registration_form"
        >
          Potvrdiť účasť na oslave
        </Button>
      </Fade>
      <Fade in timeout={10000}>
        <Typography variant="h5" color="inherit" className={classes.more}>
          01.08.2021 - 13:00 | Reštaurácia
          <Box pl={1} display="inline">
            <Link
              href="https://www.google.com/maps/place/Tri+ru%C5%BEe/@48.8201493,20.3711742,17z/data=!4m12!1m6!3m5!1s0x473fb5b83d6f16d3:0x8c41ef0b4da7cfc7!2zVHJpIHJ1xb5l!8m2!3d48.8201073!4d20.3733475!3m4!1s0x473fb5b83d6f16d3:0x8c41ef0b4da7cfc7!8m2!3d48.8201073!4d20.3733475?hl=sk"
              color={'secondary'}
              target={'_blank'}
            >
              Tri Ruže
            </Link>
          </Box>
        </Typography>
      </Fade>
    </ProductHeroLayout>
  );
}

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainLayout);
