export const photos = [
  {
    src: "/static/custom-images/love.jpg",
    width: 4,
    height: 3
  },
  {
    src: "/static/custom-images/rose.jpg",
    width: 3,
    height: 4
  },
  {
    src: "/static/custom-images/proposal.jpg",
    width: 3,
    height: 4
  },
  {
    src: "/static/custom-images/glacier.jpg",
    width: 4,
    height: 3
  },
  {
    src: "/static/custom-images/justmar.jpg",
    width: 3,
    height: 4
  },
  {
    src: "/static/custom-images/bikem.jpg",
    width: 4,
    height: 3
  },
  {
    src: "/static/custom-images/troll.jpg",
    width: 4,
    height: 3
  },
  {
    src: "/static/custom-images/faroe.jpg",
    width: 4,
    height: 3
  },
  {
    src: "/static/custom-images/just.jpg",
    width: 1,
    height: 1
  },
  {
    src: "/static/custom-images/tent.jpg",
    width: 4,
    height: 3
  },
  {
    src: "/static/custom-images/denmark.jpg",
    width: 4,
    height: 3
  },
  {
    src: "/static/custom-images/love2.jpg",
    width: 4,
    height: 3
  }
];
